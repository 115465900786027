import React, { useEffect } from "react"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image";
import baguetteBox from 'baguettebox.js';

import Trip from '../components/trip';

import Layout from "../components/layout"
import SEO from "../components/seo"

import HTML from '../components/html';

import map1 from '../images/map1.jpg';
import map2 from '../images/map2.jpg';
import map3 from '../images/map3.jpg';
import map4 from '../images/map4.jpg';
import MoreLink from "../components/moreLink";
import LeadItem from '../components/leadItem';

import treeIcon from '../images/tree-icon.png';
import jeepIcon from '../images/jeep-icon.png';
import mountainIcon from '../images/mountain-icon.png';
import binocularIcon from '../images/binocular-icon.png';


const JeepSafariPage = ({ intl }) => {
	const {trip1, trip2, trip3, trip4} = useStaticQuery(
		graphql`
		  query {
			trip1: allFile (
			  sort: { fields: name, order: DESC }
			  filter: { relativeDirectory: { eq: "trip1" } }
			) {
			  edges {
				node {
				  id
				  name
				  childImageSharp {
					fluid(maxWidth: 400) {
					  ...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				  }
				  big: childImageSharp {
					fluid(maxWidth: 1200) {
					  ...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				  }
				}
			  }
			}
			trip2: allFile (
			  sort: { fields: name, order: DESC }
			  filter: { relativeDirectory: { eq: "trip2" } }
			) {
			  edges {
				node {
				  id
				  name
				  childImageSharp {
					fluid(maxWidth: 300) {
					  ...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				  }
				  big: childImageSharp {
					fluid(maxWidth: 1200) {
					  ...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				  }
				}
			  }
			}
			trip3: allFile (
				sort: { fields: name, order: DESC }
				filter: { relativeDirectory: { eq: "trip3" } }
			  ) {
				edges {
				  node {
					id
					name
					childImageSharp {
					  fluid(maxWidth: 400) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					  }
					}
					big: childImageSharp {
					  fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					  }
					}
				  }
				}
			  }
			  trip4: allFile (
				sort: { fields: name, order: DESC }
				filter: { relativeDirectory: { eq: "trip4" } }
			  ) {
				edges {
				  node {
					id
					name
					childImageSharp {
					  fluid(maxWidth: 300) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					  }
					}
					big: childImageSharp {
					  fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					  }
					}
				  }
				}
			  }
		  }
		`
	);

	useEffect(() => { 
		baguetteBox.run('.gallery--trip1');
		baguetteBox.run('.gallery--trip2');
		baguetteBox.run('.gallery--trip3');
		baguetteBox.run('.gallery--trip4');
	});

	console.log({trip1, trip2})
	
	return (
		<Layout pageName="jeepsafari" >
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({ id: "title" })}
				description={intl.formatMessage({ id: "description" })}
				keywords={[`gatsby`, `application`, `react`]}
			/>
			<section className="wrapper inline">
				<div className="overlap container small">
					<LeadItem
						title={intl.formatMessage({ id: "safari.p1-title" })}
						body={[
							intl.formatMessage({ id: "safari.p1" })
							]}
						icon={jeepIcon}
					/>
					</div>
					<div className="overlap container small">
					<LeadItem
						title={intl.formatMessage({ id: "safari.p2-title" })}
						body={[
							intl.formatMessage({ id: "safari.p2" })
							]}
						icon={treeIcon}
					/>
					</div>
					<div className="overlap container small">
					<LeadItem
						title={intl.formatMessage({ id: "safari.p3-title" })}
						body={[
							intl.formatMessage({ id: "safari.p3" })
							]}
						icon={mountainIcon}
					/>
					</div>
					<div className="overlap container small">
					<LeadItem
						title={intl.formatMessage({ id: "safari.p4-title" })}
						body={[
							intl.formatMessage({ id: "safari.p4" })
							]}
						icon={binocularIcon}
					/>
				</div>
			</section>
			{/* <section className="wrapper container wrap narrow">
				<div className="solidBg overlap">
					<h2 className="sectionTitle center">{intl.formatMessage({ id: "safari.sectiontitle" })}</h2>
					<p>{intl.formatMessage({ id: "safari.p1" })}</p>
					<p>{intl.formatMessage({ id: "safari.p2" })}</p>
					<p>{intl.formatMessage({ id: "safari.p3" })}</p>
					<p>{intl.formatMessage({ id: "safari.p4" })}</p>
				</div>
			</section> */}
			<section className="wrapper container wrap">
				<h2 className="sectionTitle">{intl.formatMessage({ id: "index.trips_title" })}</h2>
				<div className="container trips big" >
					<Trip
					id="trip1"
						title={intl.formatMessage({ id: "trip1.name" })}
						duration={intl.formatMessage({ id: "trip1.duration_det" })}
						map={map1}
						link="/page-2"
						body={<HTML>
							<p>{intl.formatMessage({ id: "trip1.desc_p1" })}</p>
							{intl.locale=="pl"&&<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/Fm5hPxlKHQE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>||
							<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/MR9VFXJQ1NY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>}

							
							<span className="price">{intl.formatMessage({ id: "trip2.price" })}</span>
							<h4 >{intl.formatMessage({ id: "itinerary" })}</h4>
							
						
							<ul>
								<li><FormattedMessage
									id="trip1.iten1"
									defaultMessage={intl.formatMessage({ id: "trip1.iten1" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip1.iten2"
									defaultMessage={intl.formatMessage({ id: "trip1.iten2" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								{/* <li><FormattedMessage
									id="trip1.iten3"
									defaultMessage={intl.formatMessage({ id: "trip1.iten3" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li> */}
								<li><FormattedMessage
									id="trip1.iten4"
									defaultMessage={intl.formatMessage({ id: "trip1.iten4" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip1.iten5"
									defaultMessage={intl.formatMessage({ id: "trip1.iten5" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip1.iten6"
									defaultMessage={intl.formatMessage({ id: "trip1.iten6" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip1.iten7"
									defaultMessage={intl.formatMessage({ id: "trip1.iten7" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip1.iten8"
									defaultMessage={intl.formatMessage({ id: "trip1.iten8" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
							</ul>
							{intl.locale=="pl"&&<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/BteIKBnJ2-4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>||
							<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/iiD9Yj9kKYc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>}
							
							<p>{intl.formatMessage({ id: "trip1.desc_p2" })}</p>
							<div className="gallery gallery--2row gallery--trip1">
							{trip1.edges.map((item, index) => { 
								const { node } = trip1.edges[index];
								return (
									<a href={node.big.fluid.src} className="gallery-item">
									<Img
										fluid={node.childImageSharp.fluid}
										key={node.id}
										alt={node.name.replace(/-/g, ' ').substring(2)}
									/>
									</a>
								)
							})}
							</div>
							<p className="center">
								<MoreLink to="/book/" label={intl.formatMessage({ id: "book_now" })} classList="btn"/>
							</p>
						</HTML>}
					/>
					
					<Trip
					id="trip2"
						title={intl.formatMessage({ id: "trip2.name" })}
						duration={intl.formatMessage({ id: "trip2.duration_det" })}
						map={map2}
						link="/page-2"
						body={<HTML>
							<p>{intl.formatMessage({ id: "trip2.desc_p1" })}</p>
							<span className="price">{intl.formatMessage({ id: "trip2.price" })}</span>
							<h4>{intl.formatMessage({ id: "itinerary" })}</h4>
							<ul>
								<li><FormattedMessage
									id="trip2.iten1"
									defaultMessage={intl.formatMessage({ id: "trip2.iten1" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip2.iten2"
									defaultMessage={intl.formatMessage({ id: "trip2.iten2" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip2.iten3"
									defaultMessage={intl.formatMessage({ id: "trip2.iten3" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip2.iten4"
									defaultMessage={intl.formatMessage({ id: "trip2.iten4" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip2.iten5"
									defaultMessage={intl.formatMessage({ id: "trip2.iten5" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip2.iten6"
									defaultMessage={intl.formatMessage({ id: "trip2.iten6" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip2.iten7"
									defaultMessage={intl.formatMessage({ id: "trip2.iten7" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
							</ul>
							{intl.locale=="pl"&&<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/odV5OtwHjuQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>||
							<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/iNc3frzqJYc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>}	
							<p>{intl.formatMessage({ id: "trip2.desc_p2" })}</p>
							<div className="gallery gallery--2row gallery--trip2">
							{trip2.edges.map((item, index) => { 
								const { node } = trip2.edges[index];
								return (
									<a href={node.big.fluid.src} className="gallery-item">
									<Img
										fluid={node.childImageSharp.fluid}
										key={node.id}
										alt={node.name.replace(/-/g, ' ').substring(2)}
									/>
									</a>
								)
							})}
							</div>
							<p className="center">
								<MoreLink to="/book/" label={intl.formatMessage({ id: "book_now" })} classList="btn"/>
							</p>
							
						</HTML>}
					/>
					</div>
					
					<div className="container trips big" >
					<Trip
					id="trip3"
						title={intl.formatMessage({ id: "trip3.name" })}
						duration={intl.formatMessage({ id: "trip3.duration_det" })}
						map={map3}
						link="/page-2"
						body={<HTML>
							<p>{intl.formatMessage({ id: "trip3.desc_p1" })}</p>
							{intl.locale=="pl"&&<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/S7x5Cuk20Tw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>||
							<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/oHU153hAiUc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>}
							<span className="price">{intl.formatMessage({ id: "trip2.price" })}</span>
							<h4 >{intl.formatMessage({ id: "itinerary" })}</h4>
							
							<ul>
								<li><FormattedMessage
									id="trip3.iten1"
									defaultMessage={intl.formatMessage({ id: "trip3.iten1" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip3.iten2"
									defaultMessage={intl.formatMessage({ id: "trip3.iten2" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip3.iten3"
									defaultMessage={intl.formatMessage({ id: "trip3.iten3" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip3.iten4"
									defaultMessage={intl.formatMessage({ id: "trip3.iten4" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip3.iten5"
									defaultMessage={intl.formatMessage({ id: "trip3.iten5" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip3iten6"
									defaultMessage={intl.formatMessage({ id: "trip3.iten6" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								{/* <li><FormattedMessage
									id="trip3.iten7"
									defaultMessage={intl.formatMessage({ id: "trip3.iten7" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li> */}
							</ul>
							{intl.locale=="pl"&&<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/b6-YTrMrCJA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>||
							<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/05lXOmTfAu8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>}
							<p>{intl.formatMessage({ id: "trip3.desc_p2" })}</p>
							<div className="gallery gallery--2row gallery--trip1">
							{trip3.edges.map((item, index) => { 
								const { node } = trip3.edges[index];
								return (
									<a href={node.big.fluid.src} className="gallery-item">
									<Img
										fluid={node.childImageSharp.fluid}
										key={node.id}
										alt={node.name.replace(/-/g, ' ').substring(2)}
									/>
									</a>
								)
							})}
							</div>
							<p className="center">
								<MoreLink to="/book/" label={intl.formatMessage({ id: "book_now" })} classList="btn"/>
							</p>
						</HTML>}
					/>
					
					<Trip
					id="trip4"
						title={intl.formatMessage({ id: "trip4.name" })}
						duration={intl.formatMessage({ id: "trip4.duration_det" })}
						map={map4}
						link="/page-2"
						body={<HTML>
							<p>{intl.formatMessage({ id: "trip4.desc_p1" })}</p>
							<span className="price">{intl.formatMessage({ id: "trip2.price" })}</span>
							<h4>{intl.formatMessage({ id: "itinerary" })}</h4>
							<ul>
								<li><FormattedMessage
									id="trip4.iten1"
									defaultMessage={intl.formatMessage({ id: "trip4.iten1" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip4.iten2"
									defaultMessage={intl.formatMessage({ id: "trip4.iten2" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip4.iten3"
									defaultMessage={intl.formatMessage({ id: "trip4.iten3" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								<li><FormattedMessage
									id="trip4.iten4"
									defaultMessage={intl.formatMessage({ id: "trip4.iten4" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>
								{intl.formatMessage({ id: "trip4.iten5" })!="trip4.iten5"&&<li><FormattedMessage
									id="trip4.iten5"
									defaultMessage={intl.formatMessage({ id: "trip4.iten5" })}
									values={{b: chunks => <b>{chunks}</b>}}/>
								</li>	}
							</ul>
							{intl.locale=="pl"&&<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/_OPVWCOKsgk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>||
							<div className="videoWrapper">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/ZUl3FNTK1lQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>}
								
							<p>{intl.formatMessage({ id: "trip4.desc_p2" })}</p>
							<div className="gallery gallery--2row gallery--trip2">
							{trip4.edges.map((item, index) => { 
								const { node } = trip4.edges[index];
								return (
									<a href={node.big.fluid.src} className="gallery-item">
									<Img
										fluid={node.childImageSharp.fluid}
										key={node.id}
										alt={node.name.replace(/-/g, ' ').substring(2)}
									/>
									</a>
								)
							})}
							</div>
							<p className="center">
								<MoreLink to="/book/" label={intl.formatMessage({ id: "book_now" })} classList="btn"/>
							</p>
							
						</HTML>}
					/>
				</div>
			</section>
			<section className="wrapper container wrap">
				<h2 className="sectionTitle" id="works">{intl.formatMessage({ id: "safari.how_it_works-head" })}</h2>
				<div className="solidBg">
					{intl.locale=="pl"&&<div className="videoWrapper">
						<iframe width="800" height="600" src="https://www.youtube.com/embed/9uBVkW_wpdY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>||
					<div className="videoWrapper">
						<iframe width="800" height="600" src="https://www.youtube.com/embed/SgBoqcJu_5I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>}
				<ul>
					<li><FormattedMessage
						id="safari.how_it_works-li1"
						defaultMessage={intl.formatMessage({ id: "safari.how_it_works-li1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.how_it_works-li2"
						defaultMessage={intl.formatMessage({ id: "safari.how_it_works-li2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.how_it_works-li3"
						defaultMessage={intl.formatMessage({ id: "safari.how_it_works-li3" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.how_it_works-li4"
						defaultMessage={intl.formatMessage({ id: "safari.how_it_works-li4" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
				</ul>
				</div>

			</section>
			<section className="wrapper container wrap">
				<h2 className="sectionTitle" id="practical">{intl.formatMessage({ id: "safari.practical-head" })}</h2>
				<div className="solidBg">
					{/* <div className="videoWrapper">
						<iframe width="560" height="315" src="https://www.youtube.com/embed/1KUOGUaL_hk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>	 */}
				<ul>
					<li><FormattedMessage
						id="safari.practical-li1"
						defaultMessage={intl.formatMessage({ id: "safari.practical-li1" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.practical-li2"
						defaultMessage={intl.formatMessage({ id: "safari.practical-li2" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.practical-li3"
						defaultMessage={intl.formatMessage({ id: "safari.practical-li3" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.practical-li4"
						defaultMessage={intl.formatMessage({ id: "safari.practical-li4" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.practical-li5"
						defaultMessage={intl.formatMessage({ id: "safari.practical-li5" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.practical-li6"
						defaultMessage={intl.formatMessage({ id: "safari.practical-li6" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.practical-li7"
						defaultMessage={intl.formatMessage({ id: "safari.practical-li7" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.practical-li8"
						defaultMessage={intl.formatMessage({ id: "safari.practical-li8" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					<li><FormattedMessage
						id="safari.practical-li9"
						defaultMessage={intl.formatMessage({ id: "safari.practical-li9" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li>
					{/* <li><FormattedMessage
						id="safari.practical-li10"
						defaultMessage={intl.formatMessage({ id: "safari.practical-li10" })}
						values={{b: chunks => <b>{chunks}</b>}}/>
					</li> */}
				</ul>
				</div>

			</section>

		</Layout>
	)
}

export default injectIntl(JeepSafariPage)
